body {
  margin: 0;
}

.backrest.operation-details, .backrest.file-details {
  color: gray;
  font-family: monospace;
  font-size: .7em;
}

pre {
  white-space: pre-wrap;
  overflow-x: auto;
}

.visible-on-hover .hidden-child {
  visibility: hidden;
}

.visible-on-hover:hover .hidden-child {
  visibility: visible;
}

.float-center-right {
  right: 0;
  top: "50%";
  transform: "translateY(-50%)";
  position: absolute;
}
/*# sourceMappingURL=index.27ca007c.css.map */
